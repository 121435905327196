<template>
  <main class="section-block">
    <SectionTitle title="門市進出場連結" hideBtn />

    <div class="hint-text">
      <p>請先至進場時段與收費模式開放門市進場</p>

      <p>
        如需新增門市選項，請至「基本參數設定 >
        <router-link :to="{name: 'StoreSetting'}" class="text-primary-100 underline">
          門市設定
        </router-link>
        」
      </p>
    </div>
    <BaseTable :data="displayData">
      <template #default>
        <BaseElTableColumn
          v-for="(column) in tableColumns"
          :key="column.prop"
          :prop="column.prop"
          :label="column.label"
          align="center"
        />
        <BaseElTableColumn label="操作" align="center">
          <template #default="{ row }">
            <BaseElButton type="text" @click="onCopyLink(row)">複製連結</BaseElButton>
          </template>
        </BaseElTableColumn>
      </template>
    </BaseTable>

    <!-- <Pagination
      :curPage.sync="tableOptions.page"
      :pageLimit="tableOptions.pageLimit"
      :total="tableDataCount"
      @pageChange="refresh(false)"
    /> -->
  </main>
</template>

<script>
import BaseElTableColumn from '@/components/BaseElTableColumn.vue'
import BaseTable from '@/components/BaseTable.vue'
// import Pagination from '@/components/Pagination/Pagination.vue'
import SectionTitle from '@/components/Title/SectionTitle.vue'
import { useTable } from '@/use/table'
import { map, get, find } from 'lodash'
import { computed, defineComponent, onBeforeMount } from 'vue'
import copy from 'clipboard-copy'
import notifyMessage from '@/config/notifyMessage'

export default defineComponent({
  name: 'EntryControlLinkSetting',
  components: {
    BaseTable,
    SectionTitle,
    BaseElTableColumn,
    // Pagination,
  },
  props: {
    configData: {
      type: Object,
      default: () => ({}),
    },
    branchStoreList: {
      type: Array,
      default: () => [],
    },
  },
  setup (props, { emit }) {
    const { shopId, tableData, tableDataCount, tableOptions, fetchData, fetchDataCount, pageStartIndex, loading } = useTable()
    const GetDataApi = async () => {}
    const GetDataCountApi = async () => {}

    const displayData = computed(() => {
      return map(get(props.configData, 'branchEntryLinks'), (item) => {
        return {
          branchName: get(find(props.branchStoreList, { id: item.branchId }), 'name'),
          entryLink: item.link,
        }
      })
    })

    const tableColumns = [
      { prop: 'branchName', label: '門市名稱' },
      { prop: 'entryLink', label: '進場連結' },
    ]

    const getTableData = async () => {
      const payload = {
        shopIds: shopId.value,
        start: pageStartIndex.value,
        limit: tableOptions.pageLimit,
      }
      await Promise.allSettled([
        fetchData(GetDataApi, payload),
        fetchDataCount(GetDataCountApi, payload),
      ])
    }

    const refresh = async () => {
      if (loading.table) return
      loading.table = true
      await getTableData()
      loading.table = false
    }

    const onCopyLink = (row) => {
      try {
        copy(row.entryLink)
        window.$message.success(notifyMessage.copySuccess)
      } catch (error) {
        window.$message.success(error)
      }
    }

    onBeforeMount(() => {
      refresh()
    })

    return { tableData, tableDataCount, tableOptions, displayData, tableColumns, onCopyLink }
  },
})

</script>

<style lang="postcss" scoped>
.hint-text {
  @apply py-[32px] text-gray-60;
}
</style>
