<template>
  <main class="section-block">
    <SectionTitle title="進場時段與收費模式" @edit="onEdit" />

    <div v-if="branchApplyMode === 'allBranch'" class="preview">
      <div v-for="(item, idx) in displayData" :key="idx" class="flex items-start gap-[20px]">
        <div v-if="item.label">
          {{ item.label }}
        </div>
        <div class="flex flex-col">
          <div v-for="(text, textIdx) in item.content" :key="textIdx">
            {{ text }}
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="branchApplyMode === 'eachBranch'" class="preview" style="gap: 28px">
      <div v-for="(branch, idx) in eachBranchDisplayData" :key="idx" class="flex flex-col" style="gap: 8px">
        <p class="font-bold text-gray-100 text-normal">{{ branch.name }}</p>
        <div v-for="(item, idx) in branch.dataList" :key="idx" class="flex items-start gap-[20px]">
          <div v-if="item.label">
            {{ item.label }}
          </div>
          <div class="flex flex-col">
            <div v-for="(text, textIdx) in item.content" :key="textIdx">
              {{ text }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="text-gray-80">
      所有門市不開放進場
    </div>
  </main>
</template>

<script>
import SectionTitle from '@/components/Title/SectionTitle.vue'
import { toNumber, find, join, get } from 'lodash'

import { computed, defineComponent } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'

export default defineComponent({
  name: 'EntryControlTimeAndPriceSetting',
  components: {
    SectionTitle,
  },
  props: {
    configData: {
      type: Object,
      default: () => ({}),
    },
    branchStoreList: {
      type: Array,
      default: () => [],
    },
  },
  setup (props, { emit }) {
    const router = useRouter()

    const formatPeriodsPreviewText = (periodSettings) => {
      const contents = []
      for (const period of periodSettings) {
        const { start, end } = period
        contents.push(`${start || '-'} ~ ${end || '-'}`)
      }
      return join(contents, '、')
    }

    const formatAllowResourcesPreviewText = (allowResources) => {
      if (allowResources.includes('pointCard') && allowResources.includes('punchCard')) {
        return '開放次卡與點數卡'
      }
      if (allowResources.includes('punchCard')) {
        return '開放次卡'
      }
      if (allowResources.includes('pointCard')) {
        return '開放點數卡'
      }
      return ''
    }

    const weekDays = [
      { label: '周一', value: 1 },
      { label: '周二', value: 2 },
      { label: '周三', value: 3 },
      { label: '周四', value: 4 },
      { label: '周五', value: 5 },
      { label: '周六', value: 6 },
      { label: '周日', value: 0 },
    ]

    const formatMaxDurationPreviewText = (setting) => {
      if (get(setting, 'entitlementApplyMode') === 'allEntitlement') return '限定單次進場最高時數上限'
      return '部分進場權益限定單次進場最高時數上限'
    }
    const formatOvertimeExitPreviewText = (setting) => {
      if (get(setting, 'entitlementApplyMode') === 'allEntitlement') return '開放超時出場'
      return '部分進場權益開放超時出場'
    }

    const branchApplyMode = computed(() => {
      const configData = props.configData
      const branchApplyMode = get(configData, 'entrySetting.branchApplyMode')
      return branchApplyMode
    })

    const displayData = computed(() => {
      const configData = props.configData
      const enable = configData.enable
      const branchApplyMode = get(configData, 'entrySetting.branchApplyMode')

      if (branchApplyMode === 'allBranch') {
        if (!enable) return [{ content: ['所有門市不開放進場'] }]
        const weekApplyMode = get(configData, 'entrySetting.allBranchSetting.weekApplyMode')
        if (weekApplyMode === 'allWeek') {
          const textLines = []
          const allWeekSetting = get(configData, 'entrySetting.allBranchSetting.allWeekSetting')
          const periodSettings = get(allWeekSetting, 'allTimePeriods')
          const timeApplyMode = get(allWeekSetting, 'timeApplyMode')

          // 進場時段
          if (timeApplyMode === 'allTime') {
            const allowResources = []
            if (get(allWeekSetting, 'allTimeSetting.enablePointCardEntry')) {
              allowResources.push('pointCard')
            }
            if (get(allWeekSetting, 'allTimeSetting.enablePunchCardEntry')) {
              allowResources.push('punchCard')
            }
            textLines.push(`週一至週日${formatAllowResourcesPreviewText(allowResources)}進場：${formatPeriodsPreviewText(periodSettings)}`)
          } else if (timeApplyMode === 'eachTime') {
            const eachPeriodSetting = get(allWeekSetting, 'eachTimeSettings')
            for (const eachPeriod of eachPeriodSetting) {
              handlePeriod({
                textLines,
                setting: eachPeriod.setting,
                periodSetting: eachPeriod.period,
                type: 'eachTime',
              })
            }
          }

          // 限制進場時數 && 超時出場
          handleMaxDurationAndOvertimeExit({ setting: allWeekSetting, textLines })

          return [{ content: textLines }]
        } else if (weekApplyMode === 'eachWeek') {
          const dataList = []
          const eachWeekSetting = get(configData, 'entrySetting.allBranchSetting.eachWeekSettings')

          eachWeekSetting.sort((a, b) => {
            // week === 0 排在最後
            const aWeek = toNumber(a.week)
            const bWeek = toNumber(b.week)
            if (aWeek === 0) return 1
            if (bWeek === 0) return -1
            return aWeek - bWeek
          })

          for (const weekDaySetting of eachWeekSetting) {
            const textLines = []
            const enable = get(weekDaySetting, 'enable')
            const setting = get(weekDaySetting, 'setting')
            const weekDayLabel = get(find(weekDays, { value: toNumber(weekDaySetting.week) }), 'label')
            if (!enable) {
              textLines.push('不開放進場')
              dataList.push({
                label: weekDayLabel,
                content: textLines,
              })
              continue
            }
            const timeApplyMode = get(setting, 'timeApplyMode')
            if (timeApplyMode === 'allTime') {
              const allTimePeriods = get(setting, 'allTimePeriods')
              handlePeriod({
                textLines,
                setting: setting.allTimeSetting,
                periodSetting: allTimePeriods,
              })
            } else if (timeApplyMode === 'eachTime') {
              const eachPeriodSetting = get(setting, 'eachTimeSettings')
              for (const eachPeriod of eachPeriodSetting) {
                handlePeriod({
                  textLines,
                  setting: eachPeriod.setting,
                  periodSetting: eachPeriod.period,
                  type: 'eachTime',
                })
              }
            }

            // 限制進場時數 && 超時出場
            handleMaxDurationAndOvertimeExit({ setting, textLines })

            dataList.push({
              label: weekDayLabel,
              content: textLines,
            })
          }
          return dataList
        }
      }
      if (!enable) {
        return [{ content: ['所有門市不開放進場'] }]
      }
      return []
    })

    const eachBranchDisplayData = computed(() => {
      const configData = props.configData
      const enable = configData.enable
      const branchApplyMode = get(configData, 'entrySetting.branchApplyMode')

      const branchData = []

      if (branchApplyMode === 'eachBranch') {
        const eachBranchSettings = get(configData, 'entrySetting.eachBranchSettings')

        for (const branchSetting of eachBranchSettings) {
          const setting = get(branchSetting, 'setting')
          const weekApplyMode = get(setting, 'weekApplyMode')
          const branchStoreId = get(branchSetting, 'branchId')
          const branchEnable = get(branchSetting, 'enable')

          if (!branchEnable) {
            branchData.push({
              name: get(find(props.branchStoreList, { id: branchStoreId }), 'name'),
              dataList: [{ content: ['不開放進場'] }],
            })
            continue
          }

          if (weekApplyMode === 'allWeek') {
            const textLines = []
            const allWeekSetting = get(configData, 'entrySetting.allBranchSetting.allWeekSetting')
            const periodSettings = get(allWeekSetting, 'allTimePeriods')
            const timeApplyMode = get(allWeekSetting, 'timeApplyMode')

            // 進場時段
            if (timeApplyMode === 'allTime') {
              const allowResources = []
              if (get(allWeekSetting, 'allTimeSetting.enablePointCardEntry')) {
                allowResources.push('pointCard')
              }
              if (get(allWeekSetting, 'allTimeSetting.enablePunchCardEntry')) {
                allowResources.push('punchCard')
              }
              textLines.push(`週一至週日${formatAllowResourcesPreviewText(allowResources)}進場：${formatPeriodsPreviewText(periodSettings)}`)
            } else if (timeApplyMode === 'eachTime') {
              const eachPeriodSetting = get(allWeekSetting, 'eachTimeSettings')
              for (const eachPeriod of eachPeriodSetting) {
                handlePeriod({
                  textLines,
                  setting: eachPeriod.setting,
                  periodSetting: eachPeriod.period,
                  type: 'eachTime',
                })
              }
            }

            // 限制進場時數 && 超時出場
            handleMaxDurationAndOvertimeExit({ setting: allWeekSetting, textLines })

            branchData.push({
              name: get(find(props.branchStoreList, { id: branchStoreId }), 'name'),
              dataList: [{ content: textLines }],
            })
          } else if (weekApplyMode === 'eachWeek') {
            const dataList = []
            const eachWeekSetting = get(branchSetting, 'setting.eachWeekSettings')

            eachWeekSetting.sort((a, b) => {
            // week === 0 排在最後
              const aWeek = toNumber(a.week)
              const bWeek = toNumber(b.week)
              if (aWeek === 0) return 1
              if (bWeek === 0) return -1
              return aWeek - bWeek
            })

            for (const weekDaySetting of eachWeekSetting) {
              const textLines = []
              const enable = get(weekDaySetting, 'enable')
              const setting = get(weekDaySetting, 'setting')
              const weekDayLabel = get(find(weekDays, { value: toNumber(weekDaySetting.week) }), 'label')
              if (!enable) {
                textLines.push('不開放進場')
                dataList.push({
                  label: weekDayLabel,
                  content: textLines,
                })
                continue
              }
              const timeApplyMode = get(setting, 'timeApplyMode')
              if (timeApplyMode === 'allTime') {
                const allTimePeriods = get(setting, 'allTimePeriods')
                handlePeriod({
                  textLines,
                  setting: setting.allTimeSetting,
                  periodSetting: allTimePeriods,
                })
              } else if (timeApplyMode === 'eachTime') {
                const eachPeriodSetting = get(setting, 'eachTimeSettings')
                for (const eachPeriod of eachPeriodSetting) {
                  handlePeriod({
                    textLines,
                    setting: eachPeriod.setting,
                    periodSetting: eachPeriod.period,
                    type: 'eachTime',
                  })
                }
              }

              // 限制進場時數 && 超時出場
              handleMaxDurationAndOvertimeExit({ setting, textLines })

              dataList.push({
                label: weekDayLabel,
                content: textLines,
              })
            }
            branchData.push({
              name: get(find(props.branchStoreList, { id: branchStoreId }), 'name'),
              dataList,
            })
          }
        }
      }

      if (!enable) return [{ name: '', dataList: [{ content: ['所有門市不開放進場'] }] }]
      return branchData
    })

    const handleMaxDurationAndOvertimeExit = ({ setting, textLines }) => {
      const enableMaxDuration = get(setting, 'enableMaxDuration')
      const enableOvertimeExit = get(setting, 'enableOvertimeExit')
      if (enableMaxDuration) {
        const maxDurationSetting = get(setting, 'maxDurationSetting')
        textLines.push(formatMaxDurationPreviewText(maxDurationSetting))
      }
      if (enableOvertimeExit) {
        const overtimeExitSetting = get(setting, 'overtimeExitSetting')
        textLines.push(formatOvertimeExitPreviewText(overtimeExitSetting))
      }
    }

    const handlePeriod = ({ setting, textLines, periodSetting, type }) => {
      const periodAllowResources = []
      if (get(setting, 'enablePointCardEntry')) periodAllowResources.push('pointCard')
      if (get(setting, 'enablePunchCardEntry')) periodAllowResources.push('punchCard')

      const formatedPeriods = type === 'eachTime' ? formatPeriodsPreviewText([periodSetting]) : formatPeriodsPreviewText(periodSetting)
      if (periodAllowResources.length > 1) {
        textLines.push(`${formatAllowResourcesPreviewText(periodAllowResources)}進場：${formatedPeriods}`)
      } else {
        textLines.push(`僅${formatAllowResourcesPreviewText(periodAllowResources)}進場：${formatedPeriods}`)
      }
    }

    const onEdit = () => {
      router.push({
        name: 'EditEntryControlTimeAndPriceSetting',
      })
    }
    return { displayData, onEdit, eachBranchDisplayData, branchApplyMode }
  },
})

</script>

<style lang="postcss" scoped>
.preview {
  @apply text-sub text-gray-60 whitespace-pre-line;
  @apply flex flex-col gap-[8px];
}
</style>
