<template>
  <div class="section-block">
    <SectionTitle
      title="進場權益扣除順序"
      @edit="modal.edit = true"
    />
    <div>
      <InfoList :displayData="displayData" />
    </div>

    <EditEntryControlConsumerRightOrderSettingModal
      v-if="modal.edit"
      :configData="configData"
      @close="modal.edit = false"
      @refresh="$emit('refresh')"
    />
  </div>
</template>

<script>
import InfoList from '@/components/InfoList.vue'
import SectionTitle from '@/components/Title/SectionTitle.vue'
import { get, map } from 'lodash'
import EditEntryControlConsumerRightOrderSettingModal from './EditEntryControlConsumerRightOrderSettingModal.vue'

import { computed, defineComponent, reactive } from 'vue'
export default defineComponent({
  name: 'EntryControlConsumerRightsOrderSetting',
  components: {
    SectionTitle,
    InfoList,
    EditEntryControlConsumerRightOrderSettingModal,
  },
  props: {
    configData: { type: Object, default: () => ({}) },
  },
  setup (props, { emit }) {
    const assets = {
      punchCard: '次卡',
      pointCard: '點數卡',
    }

    const modal = reactive({
      edit: false,
    })

    const displayData = computed(() => {
      const data = []

      for (let count = 0; count < get(props.configData, 'entitlementSelectionOrder.length'); count++) {
        const item = get(props.configData, `entitlementSelectionOrder[${count}]`)
        data.push({
          label: `排序${count + 1}`,
          value: get(assets, item),
        })
      }

      return data
    })
    return { displayData, modal }
  },
})

</script>

<style lang="postcss" scoped>
</style>
