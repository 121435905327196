<template>
  <BaseDialog
    title="編輯進場權益扣除順序"
    width="610px"
    :btn1Loading="loading"
    @close="$emit('close')"
    @confirm="onConfirm"
  >
    <div>
      <BaseElForm ref="formRef" :model="formData" :rules="formRules">
        <BaseElFormItem label="排序1" prop="order1">
          <BaseElSelect v-model="formData.order1" clearable>
            <BaseElSelectOption
              v-for="item in assetsOptions"
              :key="item.value"
              :disabled="disabledOption(item.value)"
              :label="item.label"
              :value="item.value"
            />
          </BaseElSelect>
        </BaseElFormItem>
        <BaseElFormItem label="排序2" prop="order2">
          <BaseElSelect v-model="formData.order2" clearable>
            <BaseElSelectOption
              v-for="item in assetsOptions"
              :key="item.value"
              :disabled="disabledOption(item.value)"
              :label="item.label"
              :value="item.value"
            />
          </BaseElSelect>
        </BaseElFormItem>
      </BaseElForm>
    </div>

    <div class="pb-[40px]">
      <ul class="list-disc" style="padding-left: 20px;">
        <li>
          會員持有複數進場權益時，系統依照上方設定來判斷使用順序。進場當下可手動變更實際要用的進場權益
        </li>
        <li>
          呈上。若會員使用點數卡進場，出場時發現，進場當下系統自動選擇使用的點數卡不夠扣除，則會自動選擇到期日第二近的點數卡來扣除
        </li>
        <li>
          呈上。若會員使用的次卡有進場時段限制，導致出場時不適用該次卡，系統不會自動扣除其他進場權益
        </li>
      </ul>
    </div>
  </BaseDialog>
</template>

<script>
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { computed, defineComponent, ref } from 'vue'
import { UpdateEntryControlConfig } from '@/api/entryControl'
import { useShop } from '@/use/shop'
import { useBaseForm } from '@/use/useForm'
import { some, get } from 'lodash'
import { noEmptyRules } from '@/validation'
import notifyMessage from '@/config/notifyMessage'

export default defineComponent({
  name: 'EditEntryControlConsumerRightOrderSettingModal',
  components: {
    BaseDialog,
  },
  props: {
    configData: { type: Object, default: () => ({}) },
  },
  setup (props, { emit }) {
    const { shopId } = useShop()
    const assetsOptions = computed(() => {
      return [
        { label: '次卡', value: 'punchCard' },
        { label: '點數卡', value: 'pointCard' },
      ]
    })

    const formRules = computed(() => {
      return {
        order1: [noEmptyRules()],
      }
    })

    const disabledOption = computed(() => {
      // 排除已選擇的
      return (asset) => {
        return some(formData, (value) => value === asset)
      }
    })

    const { formRef, formData, initFormData, loading } = useBaseForm()
    initFormData({
      order1: props.configData.entitlementSelectionOrder[0],
      order2: props.configData.entitlementSelectionOrder[1],
    })
    const onConfirm = async () => {
      if (loading.value) return
      loading.value = true
      const [res, err] = await UpdateEntryControlConfig({
        ...props.configData,
        shopId: shopId.value,
        enable: get(props.configData, 'enable', false),
        branchEntryLinks: get(props.configData, 'branchEntryLinks', []),
        entitlementSelectionOrder: [
          formData.order1,
          formData.order2,
        ],
      })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success(notifyMessage.updateSuccess)
      emit('refresh')
      emit('close')
    }
    return { onConfirm, assetsOptions, loading, formRef, formData, disabledOption, formRules }
  },
})

</script>

<style scoped lang="postcss">

</style>
