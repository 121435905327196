import { admin2 } from './instance'

// GET admin.entryControlConfig.singleFind
export const GetEntryControlConfig = async ({
  shopId,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/entryControlConfig`,
  })
}
// PUT admin.entryControlConfig.singleUpdate
export const UpdateEntryControlConfig = async ({
  shopId,
  enable,
  entrySetting,
  entitlementSelectionOrder,
}) => {
  return await admin2({
    method: 'PUT',
    url: `/${shopId}/entryControlConfig`,
    data: {
      enable,
      entrySetting,
      entitlementSelectionOrder,
    },
  })
}
// GET admin.entryControlRecord.find
export const GetEntryControlRecord = async ({
  shopId,
  status,
  exitOrigin,
  BranchId,
  MemberId,
  entryAtStart,
  entryAtEnd,
  start,
  limit,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/entryControlRecord`,
    params: {
      status,
      exitOrigin,
      BranchId,
      MemberId,
      entryAtStart,
      entryAtEnd,
      start,
      limit,
    },
  })
}
// GET admin.entryControlRecord.count
export const GetEntryControlRecordCount = async ({
  shopId,
  status,
  exitOrigin,
  BranchId,
  MemberId,
  entryAtStart,
  entryAtEnd,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/entryControlRecord/count`,
    params: {
      status,
      exitOrigin,
      BranchId,
      MemberId,
      entryAtStart,
      entryAtEnd,
    },
  })
}
// GET admin.entryControlRecord.findOne
export const FindEntryControlRecord = async ({
  shopId,
  id,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/entryControlRecord/${id}`,
  })
}
// POST admin.entryControlRecord.executeEntry
export const ExecuteEntryControlRecord = async ({
  shopId,
  identity,
  guestInfo,
  entryEntitlementType,
  entryEntitlementId,
  entryAt,
  entryNote,
  branchId,
  memberId,
}) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/entryControlRecord/executeEntry`,
    data: {
      identity,
      guestInfo,
      entryEntitlementType,
      entryEntitlementId,
      entryAt,
      entryNote,
      branchId,
      memberId,
    },
  })
}
// GET admin.entryControlSystemEntitlement.findOneByEntitlementTypeAndId
export const FindEntryControlSystemEntitlement = async ({
  shopId,
  entitlementType,
  entitlementId,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/entryControlSystemEntitlement/findOneByEntitlementTypeAndId`,
    params: {
      entitlementType,
      entitlementId,
    },
  })
}
// POST admin.entryControlRecord.getMemberEntryEntitlements
export const GetMemberEntryEntitlements = async ({
  shopId,
  branchId,
  memberId,
  entryAt,
}) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/entryControlRecord/getMemberEntryEntitlements`,
    data: {
      branchId,
      memberId,
      entryAt,
    }
  })
}
// POST admin.entryControlRecord.executeExit
export const ExecuteExitControlRecord = async ({
  shopId,
  exitAt,
  exitNote,
  branchId,
  entryControlRecordId,
}) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/entryControlRecord/executeExit`,
    data: {
      exitAt,
      exitNote,
      branchId,
      entryControlRecordId,
    },
  })
}
// POST admin.entryControlRecord.preExitEntitlement
export const PreExitEntitlement = async ({
  shopId,
  branchId,
  entryControlRecordId,
  exitAt,
}) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/entryControlRecord/preExitEntitlement`,
    data: {
      branchId,
      entryControlRecordId,
      exitAt,
    },
  })
}
